<template>
    <div class="wizard">

        <div class="wizard__item step" :class="{'dev_loading': loading || !currentStep}">
          <tempalte v-if="!isQuoting && !isBulkEdit">
            <div class="wizard__nav" v-if="data && data.breadcrumbs && data.breadcrumbs.length > 1">
                  <svg-icon-qouting  class="wizard__btn_back" data="fas fa-arrow-left" v-if="data.history && data.history.length > 0 && data.current_step.id != 999" @click="goToRoute('prev'),hideButtons=false" />
                  <BreadCrumbs
                      :steps="data.breadcrumbs"
                      @jumpTo="goToRoute"
                      v-if="!isQuoting"
                  />
              </div>
            <div  class="wizard__btn_back" v-else></div>
          </tempalte>
            <div v-if="loading || !currentStep" class="tw-flex tw-justify-center tw-items-center tw-flex-col tw-gap-4 tw-h-full">
              <Loader class="wizard__loader" customClass="big" v-if="loading || !currentStep" >
                <p v-if="loadingText">{{ loadingText }}</p>
              </Loader>
            </div>
            <template v-else>
                <div class="step__title" v-if="currentStep.title">
                  <div>
                    <svg-icon-qouting class="wizard__btn_back qp_back" data="fas fa-arrow-left" v-if="isQuoting && data.history && data.history.length > 0 && data.current_step.id != 999" @click="goToRoute('prev')" />
                    <span class="title-text">{{ !data.current_step.option ? data.current_step.name : data.current_step.option }}</span>
                    <div class="tw-ml-2 tw-inline-block">
                        <HelpTopic
                            v-if="data.current_step.help_topic_url"
                            :iframe-data="data.current_step.help_topic_url"
                        />
                    </div>
                    <div>
                    <small class="title-note">{{data.current_step.note}}</small>
                    </div>
                  </div>

                </div>

                <div class="wizard__content"
                    :class="currentStep.className"
                    :style="{height: wizardHeight}"
                     :id="currentStep.component"
                >
                    <component
                        ref="refComponent"
                        :area="area"
                        :is="currentStep.component"
                        :stepName="data.current_step.name"
                        :items="propsItems"
                        :allData="data"
                        :isQuoting="isQuoting"
                        :showAddNewAccessories="showAddNewAccessories"
                        :isBulkEdit="isBulkEdit"
                        :mode="mode"
                        @submit="submit"
                        @optionSubmit="optionSubmit"
                        @nextOption="nextOption"
                        @jumpTo="goToRoute"
                        @updColorSection="updateOptionColour"
                        @updColor="nextStep('updColor')"
                        @hideButtons="(value) => hideButtons = value"
                    />
<!--                  @updColorSection="nextStep('updColorSection')"-->
                </div>
            </template>

            <Panel
                :area="area"
                :isQuoting="isQuoting"
                :hideButtons="hideButtons"
                :buttons="currentStep.buttons"
                :allData="data"
                ref="refPanel"
                :price="isBulkEdit? null: price"
                v-if="!loading && currentStep && currentStep.buttons || !loading && price"
                @next="nextStep"
                @save="save('save')"
                @saveNew="save('save-new')"
                @saveClone="save('save-clone')"
                @createPackage="createPackage()"
                @skipPackage="skipPackage()"               
            />

            <UnsavedDataModal
                v-model="showUnsavedModal"
                @loadData="initWizard($event), closeUnsavedModal()"
                @closeModal="initWizard(), closeUnsavedModal('clear')"
            />
            <PackagesModal v-if="showPackageModal" @close="showPackageModal = false" :data="data"/>
        </div>

      <PCAlert class="action-required-modal" v-if="showPCAlert"
               :modelValue="true"
               :show="showPCAlert"
               @update:show="showPCAlert=event"
               @confirmClicked="continueWithColour"
               @cancelClicked="closePcAlert"
      >
      </PCAlert>
<!--      <Modal class="action-required-modal" v-if="showPCAlert" :modelValue="true" @update:modelValue="closePcAlert()">-->
<!--&lt;!&ndash;&lt;!&ndash;         @update:modelValue="$emit('update:modelValue', $event)"&ndash;&gt;&ndash;&gt;-->
<!--        <div style="width: 100%;">-->
<!--          <div class="modal__title">-->
<!--            Action required-->
<!--          </div>-->
<!--          <div class="modal__desc">-->
<!--            Selected colour may incur powder coating charge depending on volume. Do you wish to proceed?-->
<!--            <br>-->
<!--            <br>-->
<!--            <Checkbox-->
<!--              class="position__item"-->
<!--              :data="{key: 'doNotShowPcAlert', label : 'Do not show this alert for this order'}"-->
<!--              :disabled="false"-->
<!--              @update:modelValue="updatePcFlag"-->
<!--              v-model="supressPCAlert"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="modal__actions">-->
<!--            <Button :data="{title: 'Yes'}" @click="continueWithColour"/>-->
<!--            <Button :data="{title: 'No, let me pick another colour'}" @click="closePcAlert"/>-->
<!--          </div>-->
<!--        </div>-->
<!--      </Modal>-->

    </div>
</template>

<script>
import { ref, computed, onBeforeUnmount, onMounted, defineAsyncComponent  } from 'vue'
const BreadCrumbs = defineAsyncComponent(() => import('./components/BreadCrumbs'))
const Type = defineAsyncComponent(() => import('./components/steps/Type'))
const Layout = defineAsyncComponent(() => import('./components/steps/Layout'))
const LayoutPriceFrom = defineAsyncComponent(() => import('./components/steps/LayoutPriceFrom'))
const Size = defineAsyncComponent(() => import('./components/steps/Size'))
const Color = defineAsyncComponent(() => import('./components/steps/Color'))
const Option = defineAsyncComponent(() => import('./components/steps/Option'))
const Location = defineAsyncComponent(() => import('./components/steps/Location'))
const AllOptions = defineAsyncComponent(() => import('./components/steps/AllOptions'))
const Panel = defineAsyncComponent(() => import('./components/Panel'))
const HelpTopic = defineAsyncComponent(() => import('./components/elements/HelpTopic'))
const UnsavedDataModal = defineAsyncComponent(() => import('./components/modals/UnsavedDataModal.vue'))
const Packages = defineAsyncComponent(() => import('./components/steps/Packages'))
const PackagesModal = defineAsyncComponent(() => import('./components/modals/packages/PackagesModal.vue'))
import Modal from './components/elements/Modal.vue'
import Button from './components/form/Button.vue'
import Checkbox from './components/form/Checkbox.vue'
import PCAlert from './components/modals/PCChargeAlertModal.vue'

import { useOptionLogic } from './composables/estimate/create'
import _ from "lodash";

// import Insert from './components/steps/Insert'

import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import api from './services/wizard.service'
import Loader from './components/elements/Loader'
import { wizardSteps } from './store/constant'
import { sortItems } from './helpers/objects'
import { pcChargeStore } from "./components/estimate/pc-charge/store";

export default({
    // props: ['isModal', 'isQuoting', 'mode','showAddNewAccessories', 'isBulkEdit'],
    props: {
      area: String,
      isModal: Boolean,
      isQuoting: Boolean,
      mode: String,
      showAddNewAccessories: Boolean,
      isBulkEdit: {
        type: Boolean,
        default: false,
      },
      initialDataForBulkEdit: {
        type: Object,
        default: null
      },
      beforeSubmit: {
        type: Function,
        default: () => true
      }
    },
    components: {
    BreadCrumbs,
    LayoutPriceFrom,
    Layout,
    Option,
    Location,
    Loader,
    Type,
    Color,
    Size,
    // Insert,
    HelpTopic,
    Panel,
    AllOptions,
    UnsavedDataModal,
    Packages,
    PackagesModal,
    Modal,
    Button,
    Checkbox,
    PCAlert,
},
    setup(props, { emit }) {
        const loading = ref(true)
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const previousClickWasBack = ref(false)
      const showPCAlert =  computed(() => {return pcChargeStore.showAlert && !props.isQuoting})
      const supressPCAlert = ref(pcChargeStore.supressAlert || props.isQuoting)

        const showUnsavedModal = ref(false)
        const loadingText = ref(null)

        const optionLogic = useOptionLogic({})

        const data = ref({mode: props.mode})
        const wizard = ref(wizardSteps)

        const refComponent = ref(null)
        const hideButtons =ref(false)

        const showPackageModal = ref(false)
        const isFromAllOptions = ref(false)
        localStorage.setItem('isQuoting', !!props.isQuoting)

        onMounted(() => {
          if(props.isModal) {
            document.querySelector('body').style.overflow = 'hidden'
          }
        })

        onBeforeUnmount(() => {
          document.querySelector('body').style.overflow = ''
        })

        const pendingData = ref(null)

        if(props.isBulkEdit) {
          // eslint-disable-next-line vue/no-setup-props-destructure
          data.value = props.initialDataForBulkEdit
          store.commit('wizard/setField', { field: 'price', value: null})
          getOptionsPrice()
          loadingLogic(false)
        }

        const propsItems = computed(() => {
            if(data.value.current_step.settings?.data && data.value.current_step.name !== 'Option') return data.value.current_step.settings
            return data.value
        })

        if(!props.isModal && !props.isBulkEdit) checkUnsavedData()
        if(!showUnsavedModal.value && !props.isBulkEdit) initWizard()

        const price = computed(() => {
            if(!data.value.dry_price) return 0
            return data.value.dry_price + store.getters['wizard/currentPrice']
        })

        const currentStep = computed(() => {
            const stepType = data.value?.current_step?.name.toLowerCase().split(' ').join('_')

            if(stepType) return wizard.value[stepType]
            return null
        })

        const wizardHeight = computed(() => {
            if(currentStep.value.component == 'type') return 'calc(100% - 49px)'
            if(currentStep.value.buttons || price.value) return 'calc(100% - 129px)'
            return 'calc(100% - 90px)'
        })

        function updatePcFlag(){
          pcChargeStore.supressAlert = !supressPCAlert.value
        }

        function closePcAlert(){
          pcChargeStore.closeAlert()
        }

      function continueWithColour(){
        data.value = pcChargeStore.data
        getOptionsPrice()

        pcChargeStore.closeAlert()
      }

        async function initWizard(unsavedData) {
            const sessionStorageParams = sessionStorage.getItem('wizardParams') && JSON.parse(sessionStorage.getItem('wizardParams'))
            const params =  { ...sessionStorageParams, mode: props.mode }
            if(params.sectionType || params.sectionId) {
                const cloneData = await api.cloneItem(props.area, params.sectionType, params.sectionId)
                if(cloneData) Object.assign(params, cloneData)
            }
            if(props.showAddNewAccessories){
              data.value = {
                "current_step": {
                  "id": 200,
                  "name": "Order Accessories",
                  "option": "Accessories",
                  "help_topic_url": "https://help.quotingportal.com.au/ordering-portal_options_select-accessories",
                  "option_code": "ACC"
                },
                "steps": [],
                "breadcrumbs": [
                  {
                    "name": "Accessories",
                    "id": 200,
                    "visible": true
                  }
                ],
              }

            if(params.position_id){
              data.value.selected_accessory = (await api.showASavedAccessoryViaPosition(params.position_id)).data
            }


            }else{
              data.value = unsavedData || sortData(await api.init(params))
            }

            store.commit('wizard/setField', { field: 'price', value: null})
            getOptionsPrice()
            loadingLogic(false)
        }

        function getOptionsPrice() {
            const sizeStep = data.value?.steps.find(el => el.name == 'Size')
            if(!sizeStep) return
            const sections = sizeStep?.data?.sections
            if(sections) optionLogic.getPrice(sections)
        }

        //unsaved data logic
        function checkUnsavedData() {
            const unsavedData = sessionStorage.getItem('wizardCurrentStep') && JSON.parse(sessionStorage.getItem('wizardCurrentStep'))
            showUnsavedModal.value = !!unsavedData && unsavedData.origin == window.location.origin
            return !!unsavedData
        }

        function closeUnsavedModal(clear) {
            if(clear) sessionStorage.removeItem('wizardCurrentStep')
            showUnsavedModal.value = false
        }

        async function submit(selection, method = 'nextStep', stepId) {
            if(!props.beforeSubmit(selection, method)) return

            if(props.showAddNewAccessories) return
           /* if(previousClickWasBack.value) {
              data.value.current_step = _.find(data.value.steps,{name:data.value.current_step.name})
              let stepIndexInStepsData =  _.findIndex(data.value.steps,{name:data.value.current_step.name})
              data.value.steps = data.value.steps.slice(0, stepIndexInStepsData + 1)
              previousClickWasBack.value = false
            }*/
            loadingLogic(true)
          const d = sortData(await api[method]({...data.value, selection}))
          const isColourStep = data.value?.current_step?.name == 'Frame Colour' || data.value?.current_step?.name == 'Insert Colour';
          let skipAlert = false;
          if (data.value?.current_step?.name == 'Insert Colour'){
            skipAlert = (selection.is_same_as_frame || false)
          }

          if (isColourStep && !skipAlert){
            if (!pcChargeStore.checkPcCharges(d) || props.isQuoting) {
              data.value = d
              getOptionsPrice()
            }
          }else{
            data.value = d
            getOptionsPrice()
          }
          if (stepId)
            goToRoute(stepId)
          loadingLogic(false)
        }

        async function optionSubmit(args) {
            const type = args.type
            const sections = args.sections

            if(type == 'updColorSection') {
              let option
              for (var a in args.sections[0].options) option = a
              const groups = args.sections[0].options[option].option_settings?.groups
              submit({ settings: {groups}, "section_id": 1, "option": "PET" } , 'setOptionColor')
              return
            }

            const nextStep = args.nextStep
            const stepId = data.value.steps.find(step => step.option?.toLowerCase() == nextStep)?.id

            if(stepId) {
              const size = data.value.steps.filter(s => s.name === 'Size')[0]
              size.data.sections.forEach((s, k) => {
                Object.keys(s.options).forEach(o => {
                  if (sections[k].options[o])
                    s.options[o] = sections[k].options[o]
                })
              })
              goToRoute(stepId)
            }
            else
              submit({ sections }, 'nextStep', stepId)
        }

        function nextStep(type = 'submit') {
            refComponent.value.submit(type)
        }


        function sortData(data, key = 'sort_order') {
            const currentStepItems = data.current_step?.settings?.data
            if(currentStepItems && Array.isArray(currentStepItems) && currentStepItems[0][key]) {
                sortItems(currentStepItems, key)
            }
            //set to sessionStorage sstep if not first step
            if(!props.isModal && currentStep.value && currentStep.value.component !== 'type') {
                const origin = window.location.origin
                sessionStorage.setItem('wizardCurrentStep', JSON.stringify({...data, origin}))
            }

            return data
        }

        async function goToRoute(id) {

              const isWizardCurrentStepIsAllOptions = sessionStorage.getItem('wizardCurrentStep') && JSON.parse(sessionStorage.getItem('wizardCurrentStep')).current_step.name === "All Options"
              const isExistingLocation = sessionStorage.getItem('wizardParams') && JSON.parse(sessionStorage.getItem('wizardParams')).position_id

              if(isWizardCurrentStepIsAllOptions || isExistingLocation){
                isFromAllOptions.value = true
              }

            loadingLogic(true)
            const payload = data.value
            if(id == 'prev') {
                //Check this value when user click next
                previousClickWasBack.value = !isFromAllOptions.value
                data.value = sortData(await api.routeBack(payload))
            } else data.value = sortData(await api.routeJumpTo(id, payload))
            getOptionsPrice()
            loadingLogic(false)
        }

        function loadingLogic(value, type = null) {
            loading.value = value
            const text = type === 'save-clone'? 'Cloning in progress.': null
            loadingText.value = text
        }

        function stopLoadingButton() {
            const loadingButton = currentStep.value.buttons?.find(el => el.loading)
            if(loadingButton) loadingButton.loading = false
        }

        function skipPackage() {
            submit({id: 0}, 'nextStep')
        }

        async function save(type) {
            let result = null
            if(props.showAddNewAccessories && type === "save" ){
              let submitted = refComponent.value.submit(type)

              if(typeof submitted?.then === 'function') {
                const res = await Promise.allSettled([submitted])
                submitted = res[0].value
              }
              if(submitted){
                const wizardParams = sessionStorage.getItem('wizardParams') && JSON.parse(sessionStorage.getItem('wizardParams'))
                const orderType = localStorage.getItem('estimateType') === "orders/dealer" ? "dealer" : "customer"
                result = await api.saveNewAccessoryForOrder({order_type : orderType,selected_accessory :data.value.selected_accessory, order_id: route.params.id,position_id:wizardParams.position_id})
              }
            } else {
              const token = localStorage.getItem('token')
              if(!token) {
                emit('showRegisterModal')
                // router.replace({name: 'create', params: { saveType: type }})
                sessionStorage.setItem('wizardSaveType', type)
                stopLoadingButton()
                return
              }
              if(!type) {
                type = sessionStorage.getItem('wizardSaveType')
                sessionStorage.removeItem('wizardSaveType')
              }
              loadingLogic(true, type)
               result = await api.save(type, data.value)
              stopLoadingButton()
              loadingLogic(false)
            }

            setTimeout(() => {
              stopLoadingButton()
              loadingLogic(false)
            }, 2000);
            


            if(result) {
                emit('alert', 'Saved successfully')
                switch(type) {
                    case 'save':
                        if(props.isModal) {
                          // emit('closeModal', data.value)

                          if(route.params.id === 'new') {
                            router.push({ name: 'order-item', params: {
                                id:  result.container_id
                              }
                            }).then(() => {
                              // window.location.reload()
                            })
                            sessionStorage.removeItem('wizardCurrentStep')
                          }else {
                            emit('closeModal', data.value)
                          }
                        }
                        else {
                            const params = {}
                            const { customer_order_id, dealer_order_id } = result.container_id
                            params.id = customer_order_id || dealer_order_id
                            params.type = customer_order_id ? 'customer' : 'dealer'

                            if(props.isQuoting) {
                                params.id = result.container_id
                            }
                            router.push({ name: 'estimate-item', params })
                            sessionStorage.removeItem('wizardCurrentStep')
                        }
                        break
                    case 'save-new':
                        loadingLogic(true)
                        sessionStorage.setItem('estimateNeedLoadData', true)
                        data.value = result.builder
                        loadingLogic(false)
                        break
                  case 'save-clone':
                        data.value = result.builder

                      if(router.currentRoute.value.name !== "create") {
                        const location = data.value.steps.find(el => el.name == 'Location').data.toLowerCase().trim()
                        //Check this at estimate.vue
                        if (sessionStorage.getItem('clonedLocations')) {
                          let clonedLocations = sessionStorage.getItem('clonedLocations') + "," + location
                          clonedLocations = [...new Set(clonedLocations.split(","))]
                          sessionStorage.setItem('clonedLocations', clonedLocations.toString())
                        } else {
                          sessionStorage.setItem('clonedLocations', location)
                        }
                      }
                        sessionStorage.setItem('estimateNeedLoadData', true)
                        break
                }
            }
        }

        function createPackage(){
          showPackageModal.value = true
        }

        return {
            loading,
            loadingText,
            currentStep,
            data,
            submit,
            optionSubmit,
            goToRoute,
            wizardHeight,
            refComponent,
            nextStep,
            price,
            save,
            propsItems,
            showUnsavedModal,
            closeUnsavedModal,
            initWizard,
          hideButtons,
          createPackage,
          showPackageModal,
          showPCAlert,
          supressPCAlert,
          updatePcFlag,
          continueWithColour,
          closePcAlert,
          skipPackage,
        }
    },
})
</script>

<style lang="scss">
.wizard {
    .items__container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
    }
}
</style>

<style scoped lang="scss">
.wizard {

    height: 100%;
    width: 100%;

    ::v-deep .panel {
      position: relative;
    }

    &__loader {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__item {
     // height: auto!important;
     // flex-direction: unset!important;
     // padding: 10px 0!important;
    //  display: flex;
    //  flex-wrap: wrap;
        height: calc(100vh - 160px);
        width: 100%;
        margin: 0 auto;
        background: #fff;
        padding: 25px 37px;
        position: relative;
        overflow: hidden;
        // @media(max-width: 768px) {
        //     padding: 10px;
        //     height: auto;
        //     min-height: calc(100vh - 160px);
        //     display: flex;
        //     flex-direction: column;

        // }

        display: flex;
        flex-direction: column!important;
        padding-bottom: 0!important;
        padding-left: 0!important;
        padding-right: 0!important;
    }
    &__nav {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        padding: 0 37px;

        @media(max-width: 768px) {
          padding: 0 10px;
        }

    }
    &__btn_back {
        margin-right: 10px;
        // margin-top: 2px;
        color: var(--PrimaryButtonColour);
        cursor: pointer;
      /*padding-left: 40px;
      padding-top: 40px;*/
      @media(max-width: 768px) {
        padding-left: 10px;
        padding-top: 0;
      }

      &.qp_back{
        font-size:18px;
      }
    }
    @media(max-width: 768px) {
        overflow: scroll;
        background: white;
        &__item {
          display: flex;
          height: auto!important;
          height: 100%!important;
          // flex-direction: unset!important;
          padding: 10px 0!important;
          padding-bottom: 10!important;
          display: flex;
          flex-wrap: wrap;
          // padding-bottom: 70px!important;


          padding-bottom: 0!important;
          padding-left: 0!important;
          padding-right: 0!important;
        }

        .panel {
            flex-direction: unset;
            // position: unset!important;
            // min-height: unset;
            // left: -37px;
            // width: calc(100% + 74px);
            // bottom: unset;
            // display: flex;
            // flex-direction: row;
            // &__actions {
            //   display: inline-flex!important;
            //   justify-content: space-between!important;
            //   width: 100%;
            // }
        }
    }
}
.step  {
    &__title {
        width: 100%;
        max-width: 100vw;
        font-size: 24px;
        line-height: 27px;
        text-transform: uppercase;
        color: #000000;
        font-weight: bold;
        text-align: left;
        font-family: Sansation;
        padding: 0 37px;
     // padding-top: 40px;
     // margin-left: 40px;
      @media(max-width: 768px) {
        padding-left:10px;
        margin-bottom: 20px;
        font-size: 22px!important;
        padding-top: 0;
        margin-left: 0;
      }
    }
    .title-text {
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
        max-width: 70%;
        text-overflow: ellipsis;
        vertical-align: middle;

    }

  .title-note {
    text-transform: none;
    font-weight: normal;
    font-size: 15px;
    color: #000000;
    display: inline;
  }
}
.wizard__content {
  /*padding: 0 40px 40px 40px;
  width: 100%;
  margin-top: 0!important;
  height: auto!important;*/
  display: block;
  margin-top: 16px;
  // height: calc(100% - 90px);
  height: auto!important;;
  position: relative;
  overflow-y: auto;
  flex: 1;
  padding: 0 37px;

    @media(max-width: 768px) {
     //   height: auto;
        overflow-x: hidden;
      //  margin-top: 10px;
      padding: 0 10px;
      width: 100%;
      margin-top: 0!important;

    }
    & > div {
        min-height: 100%;
    }
    &.overflow-hidden{
        overflow: hidden;
        & > div {
            height: 100%;
        }
    }
}

.dev_loading{
  min-height : 60vh !important;
  height: auto!important;
}

.wizard .items__container {
  justify-content: space-evenly!important;
  margin-top: 0!important;
}
.action-required-modal {
  :deep(.modal) {
    width: 640px;
    height: auto;
    max-width: 100%;
    padding: 15px !important;
  }
}
.modal {
  &__title {
    text-decoration: uppercase;
    font-size: 26px;
    text-align: left;
    font-family: Sansation;
    width: 100%;
    text-align: center;
  }
  &__desc {
    font-size: 16px;
    font-weight: 300px;
    margin-top: 20px;
    text-align: left;
  }
  &__actions {
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 20px;
  }
}

</style>
